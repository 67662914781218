<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="
          'ประกาศผลการคัดเลือก ตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
            period_colleges.period_college_times +
            ' ปี : ' +
            period_colleges.period_college_year
        "
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="sedirector_apps"
          :search="search"
          item-key="sedirector_app_idref"
          :item-class="row_classes"
        >

        <template v-slot:item.sedirector_app_college_filereport="{item}">
          <div v-if="item.sedirector_app_college_report === 'receipt' || item.sedirector_app_college_report === 'missing'">
            <v-btn fab small color="info" @click="viewpdfsedirector_app_college_filereport(item.sedirector_app_college_filereport)"> <v-icon>mdi-printer</v-icon> </v-btn>
            
          </div>
          <div v-else>
              <v-chip color="grey">-</v-chip>
          </div>
        </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="item.sedirector_app_college_report === 'receipt'">
           <div>
            <v-chip dark color="primary">มารายงานตัวปกติ</v-chip>
           </div> 

           
            </div>
            <div v-else-if="item.sedirector_app_college_report === 'missing'">
              <v-chip dark color="primary">ไม่มารายงานตัว</v-chip>
            
            </div>

            <div v-else-if="item.sedirector_app_college_report === 'cc'">
              <v-chip color="red" dark>แจ้งขอยกเลิกการรายงาน</v-chip>
            </div>

            <div v-else>
              <v-btn
                @click="sedirector_receipt_report(item.sedirector_app_idref)"
                rounded
                color="warning"
              >
                <v-icon color="red" class="pr-2">mdi-email</v-icon>
                รับรายงานตัว</v-btn
              >
            </div>
          </template>

          <template v-slot:item.actions2="{ item }">
            <div v-if="item.sedirector_app_college_report === 'receipt'">   
            <v-btn rounded color="warning"> <v-icon>mdi-close</v-icon> ขอยกเลิกการรายงาน</v-btn>
            </div>
            <div v-else-if="item.sedirector_app_college_report === 'missing'">
            
              <v-btn rounded color="warning"> <v-icon>mdi-close</v-icon> ขอยกเลิกการรายงาน</v-btn>
            </div>

            <div v-else-if="item.sedirector_app_college_report === 'cc'"> <v-chip color="red" dark>แจ้งขอยกเลิกการรายงาน</v-chip></div>
             
            <div v-else>
              <v-btn
                @click="sedirector_receipt_report(item.sedirector_app_idref)"
                rounded
                color="warning"
              >
                <v-icon color="red" class="pr-2">mdi-email</v-icon>
                รับรายงานตัว</v-btn
              >
            </div>
          </template>



          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model editsedirector_appdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editsedirector_appdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-email-open"
              :title="
                'รายรายงานตัว รองผู้อำนวยการวิทยาลัย รอบที่ : ' +
                  period_colleges.period_college_times +
                  ' / ' +
                  period_colleges.period_college_year
              "
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editsedirector_appform" lazy-validation>
                <v-container grid-list-md>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editsedirector_app.sedirector_app_idcard
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >เลขบัตรประชาชน</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editsedirector_app.fristnames
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >ชื่อ-นามสกุล</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-school
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editsedirector_app.college_name
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >สังกัดเดิม
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item
                      v-if="
                        editsedirector_app.sedirector_app_collegeapp_status ===
                          'missing' ||
                          editsedirector_app.sedirector_app_collegeapp_status ===
                            'receipt'
                      "
                    >
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          <h2
                            class="red--text"
                            v-if="
                              editsedirector_app.sedirector_app_collegeapp_status ===
                                'missing'
                            "
                          >
                            ไม่มารายงานตัว
                          </h2>
                          <h2
                            class="green--text"
                            v-if="
                              editsedirector_app.sedirector_app_collegeapp_status ===
                                'receipt'
                            "
                          >
                            มารายงานตัว
                          </h2>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >สถานะการรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip
                            :href="
                              '/HrvecRegisDir/' +
                                editsedirector_app.sedirector_app_college_filereport
                            "
                            target="_blank"
                            color="info"
                            class="pa-2"
                          >
                            <v-icon class="mr-5">mdi-printer</v-icon>
                            <h2>หนังสือนำส่ง</h2>
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >หนังสือแจ้งรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon>mdi-message-text</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item
                      v-if="
                        editsedirector_app.sedirector_app_collegeapp_status ===
                          'missing' ||
                          editsedirector_app.sedirector_app_collegeapp_status ===
                            'receipt'
                      "
                    >
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          <h2 class="red--text">
                            ขอยกเลิกการรายงานข้อมูลเนื่องจากรายงานผิดพลาด
                            <br />
                            <v-btn
                              @click="sedirector_appCC()"
                              outlined
                              color="red"
                              >ขอยกเลิกการรายงาน</v-btn
                            >
                          </h2>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >สถานะการรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-layout
                    wrap
                    v-if="
                      editsedirector_app.sedirector_app_collegeapp_status ===
                        'send'
                    "
                  >
                    <v-flex md4>
                      <v-autocomplete
                        v-model="
                          editsedirector_app.sedirector_app_college_report
                        "
                        :items="sedirector_app_collegeapp_status_select"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-map-marker"
                        outlined
                        label="สถานะการรับรายงานตัว"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md8>
                      <v-file-input
                        v-model="sedirector_app_college_filereport"
                        accept=".pdf"
                        name="sedirector_app_college_filereport"
                        color="deep-purple accent-4"
                        counter
                        label="หนังสือนำส่ง .pdf"
                        placeholder="หนังสือแจ้งรับรายงานตัว"
                        outlined
                        :show-size="1000"
                        :rules="rules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                outlined
                @click.stop="editsedirector_appdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editsedirector_appSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>


         <!-- V-model pdfsedirector_app_college_filereportDialog -->
         <v-layout row justify-center>
        <v-dialog v-model="pdfsedirector_app_college_filereportDialog" max-width="80%">
          <v-card class="" elevation="2">  
              <embed :src="'/HrvecRegisDir/' + pdf_files" width="100%" height="700px">    
          </v-card>
        </v-dialog>
      </v-layout>

      
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "2",
      loading: false,
      ApiKey: "HRvec2021",
      sedirector_apps: [],
      search: "",
      pagination: {},
      singleSelect: false,
      editsedirector_appdialog: false,
      pdfsedirector_app_college_filereportDialog:false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        {
          text: "เลขที่ตำแหน่ง",
          align: "center",
          value: "sedirector_app_collegeapp_idpositions"
        },
        { text: "รายงาน", align: "center", value: "actions" },
        { text: "เอกสารแนบ", align: "center", value: "sedirector_app_college_filereport" },
        { text: "ดำเนินการ", align: "center", value: "actions2" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      period_colleges: [],
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      sedirector_appCount: [],
      userColleges: [],
      editsedirector_app: [],
      sedirector_app_collegeapp_status_select: [
        { text: "มารายงานตัว", value: "receipt" },
        { text: "ไม่มารายงานตัว", value: "missing" }
      ],
      sedirector_app_college_filereport: null,
      pdf_files:[],
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() { 
    await this.userCollege();
    await this.period_collegeQuery();
   
 
  },
  methods: {
    async viewpdfsedirector_app_college_filereport(sedirector_app_college_filereport){
      this.pdf_files = sedirector_app_college_filereport
      this.pdfsedirector_app_college_filereportDialog = true
    },

    async sedirector_receipt_report(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.editsedirector_app = result_con.data;
      this.editsedirector_appdialog = true;
      ;
    },
    async userCollege() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("user.php", {
        ApiKey: this.ApiKey,
        user_ID: userSession.user_ID
      });
      this.userColleges = result.data;
      
    },

    async editsedirector_appSubmit() {
      Swal.fire({
        title: "ยืนยันรับรายงานตัว ตำแหน่ง รองผู้อำนวยการ?",
        text: "ตำแหน่ง รองผู้อำนวยการวิทยาลัย",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          if (this.$refs.editsedirector_appform.validate()) {
            this.editsedirector_app.ApiKey = this.ApiKey;
            this.editsedirector_app.sedirector_app_college_datetime = new Date();
            let result = "";
            let uploaded = null;
            if (this.sedirector_app_college_filereport != "") {
              let formData = new FormData();
              let filename =
                this.editsedirector_app.sedirector_app_idref +
                this.time_stamp +
                "report.pdf";
              formData.append("ApiKey", this.ApiKey);
              formData.append("file", this.sedirector_app_college_filereport);
              formData.append("filename", "../HrvecRegisDir/" + filename);
              result = await this.$http.post("uploadfile.php", formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              });

              this.editsedirector_app.sedirector_app_college_filereport = filename;

              uploaded = true;
            } else {
              uploaded = false;
            }
            result = await this.$http.post(
              "sedirector_app.update.php",
              this.editsedirector_app
            );
            
            if (result.data.status == true) {
              Swal.fire({
                icon: "success",
                title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              this.sedirector_appQueryAll();
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
            this.editsedirector_appdialog = false;
          }
        }
        this.sedirector_appQueryAll();
      });
    },

    row_classes(item) {
      if (item.id_position_new > 0) {
        return "info";
      } else if (item.ccDocumentFile == "cc") {
        return "yellow";
      } else if (item.ccDocumentFilePVC == "cc") {
        return "yellow";
      }
    },

    async period_collegeQuery() {
      let result_period_college;
      result_period_college = await this.$http.post(
        "period_college.php",
        {
          ApiKey: this.ApiKey,
          period_college_enable: "1",
          period_college_id: "20101"
        }
      );
      this.period_colleges = result_period_college.data;
      if (this.period_colleges.period_college_enable != "1") {
        Swal.fire({
          icon: "error",
          title: "ยังไม่มีกำหนดการ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      } else {
        await this.sedirector_appQueryAll();
      }

    },

    async sedirector_appQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_time: this.period_colleges.period_college_times,
          sedirector_app_year: this.period_colleges.period_college_year,
          sedirector_app_collegeapp: this.userColleges.user_code
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
      
    },

    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_college_years() {
      let yyyy = this.period_colleges.period_college_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
