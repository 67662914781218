var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{attrs:{"icon":"mdi-clipboard-text","title":'ประกาศผลการคัดเลือก ตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
          _vm.period_colleges.period_college_times +
          ' ปี : ' +
          _vm.period_colleges.period_college_year}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"color":"success","loading":_vm.loading,"headers":_vm.showHeaders,"items":_vm.sedirector_apps,"search":_vm.search,"item-key":"sedirector_app_idref","item-class":_vm.row_classes},scopedSlots:_vm._u([{key:"item.sedirector_app_college_filereport",fn:function(ref){
          var item = ref.item;
return [(item.sedirector_app_college_report === 'receipt' || item.sedirector_app_college_report === 'missing')?_c('div',[_c('v-btn',{attrs:{"fab":"","small":"","color":"info"},on:{"click":function($event){return _vm.viewpdfsedirector_app_college_filereport(item.sedirector_app_college_filereport)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1):_c('div',[_c('v-chip',{attrs:{"color":"grey"}},[_vm._v("-")])],1)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.sedirector_app_college_report === 'receipt')?_c('div',[_c('div',[_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_vm._v("มารายงานตัวปกติ")])],1)]):(item.sedirector_app_college_report === 'missing')?_c('div',[_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_vm._v("ไม่มารายงานตัว")])],1):(item.sedirector_app_college_report === 'cc')?_c('div',[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v("แจ้งขอยกเลิกการรายงาน")])],1):_c('div',[_c('v-btn',{attrs:{"rounded":"","color":"warning"},on:{"click":function($event){return _vm.sedirector_receipt_report(item.sedirector_app_idref)}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"red"}},[_vm._v("mdi-email")]),_vm._v(" รับรายงานตัว")],1)],1)]}},{key:"item.actions2",fn:function(ref){
          var item = ref.item;
return [(item.sedirector_app_college_report === 'receipt')?_c('div',[_c('v-btn',{attrs:{"rounded":"","color":"warning"}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" ขอยกเลิกการรายงาน")],1)],1):(item.sedirector_app_college_report === 'missing')?_c('div',[_c('v-btn',{attrs:{"rounded":"","color":"warning"}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" ขอยกเลิกการรายงาน")],1)],1):(item.sedirector_app_college_report === 'cc')?_c('div',[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v("แจ้งขอยกเลิกการรายงาน")])],1):_c('div',[_c('v-btn',{attrs:{"rounded":"","color":"warning"},on:{"click":function($event){return _vm.sedirector_receipt_report(item.sedirector_app_idref)}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"red"}},[_vm._v("mdi-email")]),_vm._v(" รับรายงานตัว")],1)],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.editsedirector_appdialog),callback:function ($$v) {_vm.editsedirector_appdialog=$$v},expression:"editsedirector_appdialog"}},[_c('v-card',{staticClass:"mx-auto pa-6"},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"warning","icon":"mdi-email-open","title":'รายรายงานตัว รองผู้อำนวยการวิทยาลัย รอบที่ : ' +
                _vm.period_colleges.period_college_times +
                ' / ' +
                _vm.period_colleges.period_college_year}}),_c('v-card-text',[_c('v-form',{ref:"editsedirector_appform",attrs:{"lazy-validation":""}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editsedirector_app.sedirector_app_idcard))]),_c('v-list-item-subtitle',[_vm._v("เลขบัตรประชาชน")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editsedirector_app.fristnames))]),_c('v-list-item-subtitle',[_vm._v("ชื่อ-นามสกุล")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-school ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editsedirector_app.college_name))]),_c('v-list-item-subtitle',[_vm._v("สังกัดเดิม ")])],1)],1),_c('v-divider',{attrs:{"inset":""}}),(
                      _vm.editsedirector_app.sedirector_app_collegeapp_status ===
                        'missing' ||
                        _vm.editsedirector_app.sedirector_app_collegeapp_status ===
                          'receipt'
                    )?_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[(
                            _vm.editsedirector_app.sedirector_app_collegeapp_status ===
                              'missing'
                          )?_c('h2',{staticClass:"red--text"},[_vm._v(" ไม่มารายงานตัว ")]):_vm._e(),(
                            _vm.editsedirector_app.sedirector_app_collegeapp_status ===
                              'receipt'
                          )?_c('h2',{staticClass:"green--text"},[_vm._v(" มารายงานตัว ")]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v("สถานะการรับรายงานตัว")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-chip',{staticClass:"pa-2",attrs:{"href":'/HrvecRegisDir/' +
                              _vm.editsedirector_app.sedirector_app_college_filereport,"target":"_blank","color":"info"}},[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-printer")]),_c('h2',[_vm._v("หนังสือนำส่ง")])],1)],1),_c('v-list-item-subtitle',[_vm._v("หนังสือแจ้งรับรายงานตัว")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-message-text")])],1)],1):_vm._e(),_c('v-divider',{attrs:{"inset":""}}),(
                      _vm.editsedirector_app.sedirector_app_collegeapp_status ===
                        'missing' ||
                        _vm.editsedirector_app.sedirector_app_collegeapp_status ===
                          'receipt'
                    )?_c('v-list-item',[_c('v-list-item-action'),_c('v-list-item-content',[_c('v-list-item-title',[_c('h2',{staticClass:"red--text"},[_vm._v(" ขอยกเลิกการรายงานข้อมูลเนื่องจากรายงานผิดพลาด "),_c('br'),_c('v-btn',{attrs:{"outlined":"","color":"red"},on:{"click":function($event){return _vm.sedirector_appCC()}}},[_vm._v("ขอยกเลิกการรายงาน")])],1)]),_c('v-list-item-subtitle',[_vm._v("สถานะการรับรายงานตัว")])],1)],1):_vm._e()],1),(
                    _vm.editsedirector_app.sedirector_app_collegeapp_status ===
                      'send'
                  )?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md4":""}},[_c('v-autocomplete',{attrs:{"items":_vm.sedirector_app_collegeapp_status_select,"item-text":"text","item-value":"value","prepend-icon":"mdi-map-marker","outlined":"","label":"สถานะการรับรายงานตัว","required":"","rules":[function (v) { return !!v || ''; }]},model:{value:(
                        _vm.editsedirector_app.sedirector_app_college_report
                      ),callback:function ($$v) {_vm.$set(_vm.editsedirector_app, "sedirector_app_college_report", $$v)},expression:"\n                        editsedirector_app.sedirector_app_college_report\n                      "}})],1),_c('v-flex',{attrs:{"md8":""}},[_c('v-file-input',{attrs:{"accept":".pdf","name":"sedirector_app_college_filereport","color":"deep-purple accent-4","counter":"","label":"หนังสือนำส่ง .pdf","placeholder":"หนังสือแจ้งรับรายงานตัว","outlined":"","show-size":1000,"rules":_vm.rules},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var index = ref.index;
                      var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}],null,false,4244939259),model:{value:(_vm.sedirector_app_college_filereport),callback:function ($$v) {_vm.sedirector_app_college_filereport=$$v},expression:"sedirector_app_college_filereport"}})],1)],1):_vm._e()],1),_c('small',[_vm._v("* จำเป็น")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","outlined":"","rounded":""},on:{"click":function($event){$event.stopPropagation();_vm.editsedirector_appdialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")]),_vm._v("ยกเลิก ")],1),_c('v-btn',{attrs:{"large":"","color":"warning","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.editsedirector_appSubmit()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")]),_vm._v(" บันทึก ")],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.pdfsedirector_app_college_filereportDialog),callback:function ($$v) {_vm.pdfsedirector_app_college_filereportDialog=$$v},expression:"pdfsedirector_app_college_filereportDialog"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('embed',{attrs:{"src":'/HrvecRegisDir/' + _vm.pdf_files,"width":"100%","height":"700px"}})])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }